"use client";
import Dropdown from "@/components/global/cabecalho/Dropdown";
import { ptBR } from "@/i18n/pt-br";
import Logo from "@/public/images/logo_branca.png";
import { useUsuarioStore } from "@/store/usuarioStore";
import { CookieEnum } from "@/util/ChatUtils";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteCookie } from "cookies-next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { menuItems } from "./CabecalhoMenuItems";

const i18n = ptBR;

export default function Header() {
    const route = useRouter();
    const { usuario } = useUsuarioStore();
    const logoutUsuario = () => {
        deleteCookie(CookieEnum.FUNC_USUARIO, { path: "/", domain: `${process.env.NEXT_PUBLIC_DOMINIO}` });
        deleteCookie(CookieEnum.LOGIN_USUARIO, { path: "/", domain: `${process.env.NEXT_PUBLIC_DOMINIO}` });
        route.push("/login");
    };

    return (
        <header className="sticky top-0 z-50 flex h-[4%] w-full items-center justify-around border-b border-black bg-green-900 px-4 shadow-sm text-white text-sm">
            <nav className="flex w-11/12 items-center gap-4">
                <Link
                    href={`${process.env.NEXT_PUBLIC_CORE_URL}/embarque/index.html`}
                    target="_blank">
                    <Image
                        src={Logo}
                        alt={i18n.alt_logo_rodosafra}
                        width={90}
                        height={21}
                        unoptimized
                    />
                </Link>
                <ul className="flex w-full items-center gap-6 sm:gap-8">
                    <li>
                        <Link
                            href={`${process.env.NEXT_PUBLIC_CORE_URL}/carga/busca/busca.html`}
                            target="_blank">
                            <FontAwesomeIcon
                                icon={faSearch}
                                inverse
                            />
                        </Link>
                    </li>
                    {menuItems.map((item) => {
                        return item.children ? (
                            <Dropdown
                                key={item.title}
                                item={item}
                            />
                        ) : (
                            <li key={item.title}>
                                <Link
                                    href={item.route ?? "#"}
                                    target="_blank">
                                    {item.title}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
            <nav className="flex justify-end gap-4 font-bold mr-2">
                <span>{usuario?.login ?? "Não logado"}</span>
                <button onClick={logoutUsuario}>Sair</button>
            </nav>
        </header>
    );
}
