import { ptBR } from "@/i18n/pt-br";
import { ConversaType } from "@/types/ConversaTypes";
import { MensagemType } from "@/types/MensagemTypes";
import { format, isToday, parse } from "date-fns";
import { redirect } from "next/navigation";

const i18n = ptBR;
export const isNullOrEmpty = (value: string | null | undefined) => {
    return value === null || value === undefined || value === "";
};

export const redirectToLogin = () => {
    redirect("/login");
};

export const ordenarMensagensPorData = (mensagens: MensagemType[]): MensagemType[] => {
    return mensagens.sort((a, b) => {
        const formatoDataHora = "dd/MM/yyyy HH:mm:ss";
        const dataHoraEnvioA = a.dataHoraEnvio
            ? parse(a.dataHoraEnvio.toString(), formatoDataHora, new Date())
            : new Date();
        const dataHoraEnvioB = b.dataHoraEnvio
            ? parse(b.dataHoraEnvio.toString(), formatoDataHora, new Date())
            : new Date();
        if (dataHoraEnvioA < dataHoraEnvioB) {
            return -1;
        } else if (dataHoraEnvioA > dataHoraEnvioB) {
            return 1;
        } else {
            return 0;
        }
    });
};

export const agruparMensagensPorData = (mensagens: MensagemType[]): Map<string, MensagemType[]> => {
    const grupos = new Map<string, MensagemType[]>();
    mensagens.forEach((mensagem) => {
        const formatoDataHora = "dd/MM/yyyy HH:mm:ss";
        const data = parse(mensagem.dataHoraEnvio.toString(), formatoDataHora, new Date());
        const dataFormatada = isToday(data) ? "Hoje" : format(data, "dd/MM/yyyy");
        const horaFormatada = format(data, "HH:mm");

        if (!grupos.has(dataFormatada)) {
            grupos.set(dataFormatada, []);
        }
        grupos.get(dataFormatada)?.push({ ...mensagem, dataHoraEnvio: horaFormatada });
    });
    return grupos;
};

export const ordenarConversasPorData = (conversas: ConversaType[]): ConversaType[] => {
    return conversas.sort((a, b) => {
        const formatoDataHora = "dd/MM/yyyy HH:mm:ss";
        const dataHoraEnvioA = a.ultimaMensagem
            ? parse(a.ultimaMensagem.dataHoraEnvio.toString(), formatoDataHora, new Date())
            : new Date(0);
        const dataHoraEnvioB = b.ultimaMensagem
            ? parse(b.ultimaMensagem.dataHoraEnvio.toString(), formatoDataHora, new Date())
            : new Date(0);
        if (dataHoraEnvioA > dataHoraEnvioB) {
            return -1;
        } else {
            return 1;
        }
    });
};

export const adicionaOuAlteraConversaLista = (conversa: ConversaType, conversas: ConversaType[]): ConversaType[] => {
    const index = conversas.findIndex((item) => item.codConversa === conversa.codConversa);
    if (index > -1) {
        return ordenarConversasPorData([...conversas.slice(0, index), conversa, ...conversas.slice(index + 1)]);
    }
    return ordenarConversasPorData([...conversas, conversa]);
};

export const converteArquivoParaBase64 = async (arquivo: File): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = (event: ProgressEvent<FileReader>) => {
            if (typeof event.target?.result === "string") {
                const regex = /^data:(?:\w+\/[\w+]+);base64,/;
                const base64String = event.target.result.replace(regex, "");
                resolve(base64String);
            } else {
                reject(new Error("Não foi possível converter o arquivo para base64."));
            }
        };

        reader.readAsDataURL(arquivo);
    });
};
export enum TipoMensagemEnum {
    TEXT = "text",
    AUDIO = "audio",
    INTERACTIVE = "interactive",
    DOCUMENT = "document",
    IMAGE = "image",
    TEMPLATE = "template",
    STICKER = "sticker",
    REACTION = "reaction",
    UNKNOWNS = "unknowns",
    SYSTEM = "system",
    VIDEO = "video",
    LOCATION = "location",
    CONTACTS = "contacts",
    BUTTONS = "buttons",
    HISTORICO = "historico",
}

export const determinarTipoDeArquivo = (arquivo: File): TipoMensagemEnum => {
    if (arquivo.type.startsWith("image/")) {
        return TipoMensagemEnum.IMAGE;
    } else if (arquivo.type.startsWith("video/")) {
        return TipoMensagemEnum.VIDEO;
    } else if (arquivo.type.startsWith("audio/")) {
        return TipoMensagemEnum.AUDIO;
    } else {
        return TipoMensagemEnum.DOCUMENT;
    }
};

export enum CookieEnum {
    LOGIN_USUARIO = "rsUsuLogin",
    FUNC_USUARIO = "rsUsuFunc",
    JWT = "__Secure-Fgp",
}

export const retornaExtensaoNomeArquivo = (nomeArquivo: string | undefined): string => {
    if (!nomeArquivo) {
        return "";
    }
    const stringExtensao = nomeArquivo.lastIndexOf(".");
    if (stringExtensao === -1) {
        return "";
    }
    return nomeArquivo.substring(stringExtensao + 1);
};

export const gerarStringAleatoria = (tamanho: number): string => {
    let result = "";
    const characters = "0123456789";

    for (let i = 0; i < tamanho; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
    }

    return result;
};

export const converterBlobParaBase64 = async (blob: Blob): Promise<string> => {
    const arrayBuffer = await blob.arrayBuffer();
    const buffer = Buffer.from(arrayBuffer);
    return buffer.toString("base64");
};
